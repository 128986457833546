class ImageLoadingPolyfill {
	constructor() {
		if (ImageLoadingPolyfill._instance) {
			return ImageLoadingPolyfill._instance;
		}

		this._initialize();

		ImageLoadingPolyfill._instance = this;
	}

	_initialize() {
		this.registeredEvents = [];
		this.intersectionObserver = this._createIntersectionObserver();
		this._applyPolyfill = this._applyPolyfill.bind(this);
		this._onIntersection = this._onIntersection.bind(this);
		this._applyPolyfill();
	}

	/**
	 * create intersection observer
	 * @returns {IntersectioObserver|undefined} - an IntersectionObserver if available, else undefined
	 */
	_createIntersectionObserver() {
		const intersectionObserverConfig = {
			rootMargin: '0px 0px 256px 0px',
			threshold: 0.01,
			lazyloadImage: 'img[loading="lazy"]'
		};

		if ('IntersectionObserver' in window) {
			return new IntersectionObserver(
				this._onIntersection,
				intersectionObserverConfig
			);
		}
	}

	/**
	 * handle intersections
	 * @param {Array} intersectionObserverEntries_ - the intersection entries for all observed elements
	 * @param {IntersectionObserver} intersectionObserver_ - the intersection observer
	 * @returns {void}
	 */
	_onIntersection(intersectionObserverEntries_, intersectionObserver_) {
		intersectionObserverEntries_.forEach(function(entry_) {
			if (entry_.intersectionRatio === 0) {
				return;
			}
			// If the item is visible now, load it and stop watching it
			const lazyloadImage = entry_.target;

			intersectionObserver_.unobserve(lazyloadImage);
			ImageLoadingPolyfill._setImageSource(lazyloadImage);
		});
	}

	/**
	 * restore the image src of an image (with value of data-src)
	 * @param {Element} image_ - the img element
	 * @returns {void}
	 */
	static _setImageSource(image_) {
		ImageLoadingPolyfill._setPictureSources(image_);
		if (image_.dataset.srcset) {
			image_.srcset = image_.dataset.srcset;
		}
		if (image_.dataset.src) {
			image_.src = image_.dataset.src;
		}
	}

	/**
	 * restore the srcset attributes of an possible surrounding picture element
	 * @param {Element} image_ - the (lazy loading) image element
	 * @returns {void}
	 */
	static _setPictureSources(image_) {
		if (
			image_.parentNode &&
			image_.parentNode.tagName.toLowerCase() === 'picture'
		) {
			const sourcesNodeList = image_.parentNode.querySelectorAll('source');
			const sources = Array.prototype.slice.call(sourcesNodeList);

			sources.forEach(function(source_) {
				if (source_.dataset.srcset) {
					source_.srcset = source_.dataset.srcset;
				}
			});
		}
	}

	/**
	 * apply the polyfill to a (possibly) given payload
	 * @param {Object|undefined} payload_ - can receive an Object via an Event
	 * @returns {void}
	 */
	_applyPolyfill(payload_) {
		const domAreaToApply =
			payload_ && payload_.element ? payload_.element : document;
		const imagesNodeList = domAreaToApply.querySelectorAll(
			'img[loading="lazy"]'
		);
		const images = Array.prototype.slice.call(imagesNodeList);
		const eagerImagesNodeList = domAreaToApply.querySelectorAll(
			'img[loading="eager"]'
		);
		const eagerImages = Array.prototype.slice.call(eagerImagesNodeList);

		const isIntersectionObserverAvailable = this._isIntersectionObserverAvailable();
		const isScrollingPossible = this._isScrollingPossible();

		if (
			isIntersectionObserverAvailable &&
			isScrollingPossible
		) {
			const loadingPolyfill = this;
			images.forEach(function(image_) {
				loadingPolyfill._observeWithIntersectionObserver(image_);
			});
			eagerImages.forEach(function(image_) {
				ImageLoadingPolyfill._setImageSource(image_);
			});
			this._enablePrintingFallback(images);
		}
		else {
			ImageLoadingPolyfill._setAllImageSources(images);
			ImageLoadingPolyfill._setAllImageSources(eagerImages);
		}
	}

	/**
	 * is intersection observer available
	 * @returns {boolean} - whether intersection observer is available
	 */
	_isIntersectionObserverAvailable() {
		return typeof this.intersectionObserver !== 'undefined';
	}

	/**
	 * is scrolling possible
	 * @returns {boolean} - whether scrolling is possible
	 */
	_isScrollingPossible() {
		return typeof window.onscroll !== 'undefined';
	}

	/**
	 * polyfill the loading attribute
	 * @param {Element} image_ - the img element
	 * @returns {void}
	 */
	_observeWithIntersectionObserver(image_) {
		this.intersectionObserver.observe(image_);
	}

	/**
	 * load images for printing
	 * @param {Array} images_ - images to restore
	 * @returns {void}
	 */
	_enablePrintingFallback(images_) {
		const mediaQueryList = window.matchMedia('print');

		this._handlePrintingFallback = this._handlePrintingFallback.bind(images_);
		mediaQueryList.addListener(this._handlePrintingFallback);
	}

	/**
	 * handle printing fallback (all images shall be loaded when printing)
	 * @param {MediaQueryList} mediaQueryList_ - the media query list
	 * @returns {void}
	 */
	_handlePrintingFallback(mediaQueryList_) {
		const images = this;

		if (mediaQueryList_.matches) {
			ImageLoadingPolyfill._setAllImageSources(images);
		}
	}

	/**
	 * restore images sources for NodeList with images
	 * @param {Array} images_ - the images to restore
	 * @returns {void}
	 */
	static _setAllImageSources(images_) {
		images_.forEach(function(image_) {
			ImageLoadingPolyfill._setImageSource(image_);
		});
	}

	/**
	 * register custom event to trigger the polyfill during lifecycle
	 * @param {String} eventName_ - name of the Event to listen for
	 * @returns {void}
	 */
	registerCustomEvent(eventName_) {
		if (this.registeredEvents.indexOf(eventName_) === -1) {
			this.registeredEvents.push(eventName_);
			document.addEventListener(eventName_, this._applyPolyfill);
		}
	}
}

const imageLoadingPolyfill = new ImageLoadingPolyfill();
export {
	imageLoadingPolyfill,
	ImageLoadingPolyfill as _ImageLoadingPolyfillClass
};
